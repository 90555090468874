import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../config/config";
import CardLoader from "../loader/CardLoader";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import ReactDatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { handleSearchAdresses, handleChangeSearchedAdress, handleChangeStringField } from "../functions/reactFieldChange";
import { phonesNumberCountries } from "../config/constant";
import PhoneInput from 'react-phone-number-input';
import { IFieldAdresse, IClientAnnuaire, IClientAnnuaireFields, selectOption, updateAnnuaireProps, IClientAnnuaireData, TrombiGrasseProps, IClientsAnnuaireInfoComplementaire } from "../type/type";
import { useQuery, useMutation } from "react-query";
import 'react-phone-number-input/style.css';
import axios, { AxiosError } from "axios";

const TrombiGrasse = ({editPhoto}: TrombiGrasseProps) => 
{
    const logo                                      = require("../assets/img/logo.png")
    const noImage                                   = require("../assets/img/no-image.png")
    const { id }                                    = useParams()
    const [ loading, setLoading ]                   = useState<boolean>(true)
    const [adresseSelected, setAdresseSelected]     = useState<IFieldAdresse>({
        label: '',
        name: '',
        postcode: '',
        city: ''
    })
    const inputFileReference                                                    = useRef<any>(null)
    const [hoverNewImage, setHoverNewImage]                                     = useState<boolean>(false)
    const [defaultValueInfoComplementaire, setDefaultValueInfoComplementaire]   = useState<selectOption[]>([])
    const [fields, setFields] = useState<IClientAnnuaireFields>({
        nom: ""
        ,prenom: ""
        ,tel: ""
        ,portable: ""
        ,fax: ""
        ,adresse: ""
        ,adresse2: ""
        ,eMail: ""
        ,codePostal: ""
        ,ville: ""
        ,casepalais: ""
        ,commissaireCompte: false
        ,sermentDate: null
        ,specialite : ""
        ,infoComplementaire: []
        ,newPhotoFile: ""
    })
    const {data: clientAnnuaire } = useQuery<IClientAnnuaire>
    (
        "clientAnnuaire"
        ,async () => 
        {
            return await axios.get(`${config.backUrl}`, 
            {
                params : 
                {
                    controller: "annuaire",
                    id : id
                }
            }).then(response => 
            {
                setLoading(false)
                return response.data
            })
            .catch((error) => 
            {
                setLoading(false)
                if (error.response) 
                {
                    console.error(error.response.data.detail)
                } else if (error.request) 
                {
                    console.error(error.request)
                } else {
                    console.error(error.message)
                }
            })
        }
        ,
        {
            enabled : loading
        }
    )
    const onSelectFile = (event: any) => 
    {
        event.preventDefault();
        let files;
        if (event.dataTransfer) 
        {
            files = event.dataTransfer.files;
        } else if (event.target) {
            files = event.target.files;
        }
        const extension = files[0]["name"].split('.').pop().toLowerCase()
        if(extension !== "jpg" && extension !== "jpeg")
        {
            toast.error("Seules les images au format JPG sont supportées !")
            inputFileReference.current.value = ""
        }else
        {
            const reader = new FileReader();
            reader.onload = () => 
            {
                setFields({...fields, "newPhotoFile" : reader.result as any});
            };
            reader.readAsDataURL(files[0]);
        }
    }
    const mutationAnnuaire = useMutation(
        async ({fields, id} : updateAnnuaireProps) => 
        {
            let data = {
                ...fields,
                id: id,
                controller: "annuaire",
                action: "patch",
                formType : "trombiGrasse"
            } as IClientAnnuaireData;
            if(data.sermentDate != null)
            {
                data.dateSerment =  data.sermentDate.toLocaleDateString("fr")
                delete data.sermentDate
            }
            return axios.post(config.backUrl, data, {
                headers : {
                    "Content-Type" : "application/x-www-form-urlencoded"
                }
            })
        },
    )
    const handleSaveData = (id: number) => 
    {
        const toastAnnuaire = toast.loading("Enregistrement en cours ...",
        {
            type: "success",
            position: "bottom-left",
            draggable: false,
            progress: 0,
            theme: "light"
        })
        mutationAnnuaire.mutateAsync({fields : fields, id: id } , 
        {
            onSuccess: () => 
            {
                toast.update(toastAnnuaire, 
                {
                    isLoading: false,
                    hideProgressBar: true,
                    render: "Enregistrement effectué avec succès !"
                })
                setTimeout(() => { toast.dismiss(toastAnnuaire) }, 5000)
            },
            onError: (error) => 
            {
                const data = error as AxiosError
                toast.update(toastAnnuaire, 
                    {
                        isLoading: false,
                        hideProgressBar: true,
                        type: "error",
                        render: "Erreur lors de l'enregistrement, veuillez contacter l'administrateur SVP!"
                    })
                    setTimeout(() => { toast.dismiss(toastAnnuaire) }, 5000)
                console.error(data.message)
            }
        })
    }
    const {data: languesData } = useQuery<IClientsAnnuaireInfoComplementaire[]>(
        'infoComplementaireLangues'
        ,async () => await axios.get(`${config.backUrl}`,
        {
            params : 
            {
                controller: "annuaireLangue"
            }
        }).then(response => response.data))
        
    const {data: specialitesData } = useQuery<IClientsAnnuaireInfoComplementaire[]>(
        'infoComplementaireSpecialites'
        ,async () => await axios.get(`${config.backUrl}`,
        {
            params : 
            {
                controller: "annuaireSpecialite"
            }
        }).then(response => response.data))
    
    const langues: IClientsAnnuaireInfoComplementaire[] = languesData ?? []
    const specialites: IClientsAnnuaireInfoComplementaire[] = specialitesData ?? []
    const infoComplementaire = (clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase() === "td") ? langues : specialites;
    const optionsInfoComplementaire: selectOption[] = infoComplementaire.map((item) => 
    { 
        return { label: item.label, value : item.label} as selectOption;
    });
    useEffect(() => 
    {
        setFields({
            nom: clientAnnuaire?.idClient.nom ?? ""
            ,prenom: clientAnnuaire?.idClient.prenoms ?? ""
            ,tel: clientAnnuaire?.idClient.telFixe ?? ""
            ,fax: clientAnnuaire?.idClient.fax ?? ""
            ,adresse: clientAnnuaire?.idClient.adresse ?? ""
            ,adresse2: clientAnnuaire?.idClient.adresseComplement ?? ""
            ,eMail: clientAnnuaire?.idClient.email ?? ""
            ,codePostal: clientAnnuaire?.idClient.codePostal ?? ""
            ,ville: clientAnnuaire?.idClient.ville ?? ""
            ,infoComplementaire: clientAnnuaire?.idClient.infoComplementaire ?? []
            ,commissaireCompte: clientAnnuaire?.commissaireCompte ?? false
            ,portable: clientAnnuaire?.idClient.telMobile ?? ""
            ,casepalais: clientAnnuaire?.idClient.caseAvocat ?? ""
            ,sermentDate: (clientAnnuaire !== undefined && clientAnnuaire.idClient.dateSerment !== undefined && clientAnnuaire.idClient.dateSerment !== null) ? new Date(clientAnnuaire.idClient.dateSerment) : null
            ,specialite: clientAnnuaire?.idClient.specialite ?? ""
        })
        if(clientAnnuaire !== undefined && clientAnnuaire.idClient.infoComplementaire !== undefined
             && clientAnnuaire.idClient.infoComplementaire.length > 0)
        {
            const defaultValue: selectOption[] = clientAnnuaire.idClient.infoComplementaire.map((item) => 
            { 
                return { label: item, value : item }
            })
            setDefaultValueInfoComplementaire(defaultValue)
        }
    }, [ clientAnnuaire ])

    useEffect(() => 
    {    
        if (adresseSelected.label) 
        {
            setFields({
                ...fields,
                adresse: adresseSelected.name,
                codePostal: adresseSelected.postcode,
                ville: adresseSelected.city
            })
        }
    }, [adresseSelected, fields])
    return (
        <div className='container-fluid'>
            <div className="row d-flex justify-content-center">
                <div className="col-10">
                    <header className='d-flex justify-content-center my-4'>
                        <img src={logo} alt="Annuaire Tribune Bulletin Côte d'Azur" title="Annuaire Tribune Bulletin Côte d'Azur" />
                    </header>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className='col-8'>
                    {loading 
                        &&
                            (<div className="card mt-4 shadow">
                                <div className="card-body">
                                    <CardLoader />
                                </div>
                            </div>)
                        ||
                        (
                            <>
                                <div className="card mt-4 shadow">
                                    <div className="card-header fw-bold fs-4">
                                        Mise à jour des données 
                                        {clientAnnuaire?.id
                                            &&
                                            (
                                                <h4 className="card-subtitle fw-normal fs-5 my-1 text-muted">Rubrique : {clientAnnuaire.idActivite.libelle} </h4>
                                            )
                                        }
                                    </div>
                                    <div className="card-body py-4">
                                        {(clientAnnuaire !== undefined && clientAnnuaire.id !== undefined)
                                            && 
                                            (
                                                <>
                                                    <div className="row my-3">
                                                        <div className="col-2 d-flex justify-content-start">
                                                            <img src={((clientAnnuaire.photoUrl != undefined && clientAnnuaire.photoUrl != "") ? clientAnnuaire.photoUrl : noImage )} className="img-fuild shadow rounded-2" style={{width:"100px",height:"115px"}} />
                                                        </div>
                                                        <div className="col-4">
                                                            {(fields != undefined && fields.newPhotoFile!= undefined && fields.newPhotoFile != "")
                                                                && (
                                                                    <div
                                                                        style={{width:"100px",height:"115px", position: "relative", overflow: "hidden"}}
                                                                        onMouseOver={() => setHoverNewImage(true)}
                                                                        onMouseLeave={() => setHoverNewImage(false)}
                                                                        className="mb-2"
                                                                        >
                                                                            <img src={fields.newPhotoFile} className="img-fuild shadow rounded-2" style={{width:"100%"}} />
                                                                            {hoverNewImage && 
                                                                                (
                                                                                    <Button
                                                                                        size="sm"
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: "5px",
                                                                                            right: "5px"
                                                                                        }}
                                                                                        variant="danger" onClick={() => setFields({...fields, "newPhotoFile" : ""})}>
                                                                                        Annuler
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                    </div>
                                                                )
                                                            }
                                                            <input
                                                                id='filename'
                                                                name='filename'
                                                                type='file'
                                                                accept="image/jpg,image/jpeg"
                                                                className='form-control form-control-sm'
                                                                onChange={onSelectFile}
                                                                ref={inputFileReference}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="nom" className="form-label">Nom</label>
                                                            <input type="text" 
                                                                className="form-control" 
                                                                name="nom"
                                                                value={fields.nom} 
                                                                onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="prenom" className="form-label">Prénom</label>
                                                            <input type="text" className="form-control" 
                                                                value={fields.prenom} 
                                                                name="prenom"
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="eMail" className="form-label">E-mail</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.eMail} 
                                                                    name="eMail"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="tel" className="form-label">Téléphone</label>
                                                                    <PhoneInput
                                                                        name='tel'
                                                                        value={fields.tel}
                                                                        onChange={(value) =>{ 
                                                                            setFields({ ...fields, tel: value ?? ""})
                                                                        }}
                                                                        displayInitialValueAsLocalNumber
                                                                        defaultCountry="FR"
                                                                        autoComplete={'new-' + fields.tel}
                                                                        countries={phonesNumberCountries}
                                                                    />
                                                                </div>
                                                                {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                                        && ["av", "ej", "td", "ec", "hu", "no"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                                    &&
                                                                    (
                                                                        <div className="col">
                                                                            <label htmlFor="portable" className="form-label">Portable</label>
                                                                            <PhoneInput
                                                                                name='portable'
                                                                                value={fields.portable}
                                                                                onChange={(value) => {
                                                                                    setFields({ ...fields, portable: value ?? "" })
                                                                                }}
                                                                                displayInitialValueAsLocalNumber
                                                                                defaultCountry="FR"
                                                                                autoComplete={'new-' + fields.portable}
                                                                                countries={phonesNumberCountries}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresse" className="form-label">Adresse</label>
                                                            <AsyncSelect
                                                                placeholder="Rechercher adresse..."
                                                                className="basic-single"
                                                                id='selectAdresse'
                                                                name="searchAdresse"
                                                                noOptionsMessage={() => "Aucune adresse correspondante"}
                                                                isClearable={true}
                                                                loadingMessage={() => "Recherche en cours..."}
                                                                loadOptions={handleSearchAdresses} 
                                                                onChange={(selectedOption) => handleChangeSearchedAdress(selectedOption, setAdresseSelected)}
                                                            />
                                                            <input type="text" className="form-control mt-1" value={fields.adresse} name="adresse" 
                                                                onChange={(e) => {
                                                                    handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label htmlFor="codePostal" className="form-label">Code postal</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.codePostal} 
                                                                        name="codePostal"
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="ville" className="form-label">Ville</label>
                                                                    <input type="text" className="form-control" 
                                                                        value={fields.ville}
                                                                        name="ville"
                                                                        onChange={(e) => {
                                                                            handleChangeStringField(e, fields, setFields)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col">
                                                            <label htmlFor="adresse2" className="form-label">Complément d'adresse (optionnel)</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.adresse2} 
                                                                    name="adresse2"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="fax" className="form-label">Fax</label>
                                                            <PhoneInput
                                                                name='fax'
                                                                value={fields.fax}
                                                                onChange={(value) => {
                                                                    setFields({ ...fields, fax: value ?? "" })
                                                                }}
                                                                displayInitialValueAsLocalNumber
                                                                defaultCountry="FR"
                                                                autoComplete={'new-' + fields.fax}
                                                                countries={phonesNumberCountries}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-3">
                                                        <div className="col-6">
                                                            <label htmlFor="casepalais" className="form-label">Case Palais</label>
                                                            <input type="text" className="form-control" 
                                                                    value={fields.casepalais} 
                                                                    name="casepalais"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                        </div>
                                                        {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                        && ["av"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                        &&
                                                        (
                                                            <div className="col-6">
                                                                <label htmlFor="sermentDate" className="form-label">Date de serment</label>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <ReactDatePicker
                                                                            id='sermentDate'
                                                                            name='sermentDate'
                                                                            locale={fr}
                                                                            className="form-control"
                                                                            selected={fields.sermentDate ? fields.sermentDate : null}
                                                                            disabledKeyboardNavigation
                                                                            dateFormat="dd/MM/yyyy"
                                                                            onKeyDown={(e) => { e.preventDefault() }}
                                                                            onChange={(date) => { setFields({...fields, "sermentDate": date }) }} 
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row my-3">
                                                        {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                            && ["ej", "td"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                            &&
                                                            (
                                                                <div className="col-6">
                                                                    <label htmlFor="infoComplementaire" className="col-form-label">
                                                                        { clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase() === "td" ? "Langues" : "Spécialités" }
                                                                    </label>
                                                                    <Select
                                                                        placeholder=""
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        options={optionsInfoComplementaire}
                                                                        isMulti={true}
                                                                        defaultValue={defaultValueInfoComplementaire}
                                                                        value={fields.infoComplementaire?.map((item) => 
                                                                            { 
                                                                                return { label: item, value : item }
                                                                            })
                                                                        }
                                                                        onChange={(selectedOption) => 
                                                                        {
                                                                            setFields({...fields, "infoComplementaire": selectedOption.map(item => item.value) }) 
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        {(clientAnnuaire?.idClient.clientActivite?.codeActivite
                                                        && ["av"].includes(clientAnnuaire?.idClient.clientActivite?.codeActivite.toLowerCase()))
                                                        &&
                                                        (
                                                            <div className="col-6">
                                                                <label htmlFor="specialite" className="col-form-label">Spécialités</label>
                                                                <input type="text" className="form-control" 
                                                                    value={fields.specialite} 
                                                                    name="specialite"
                                                                    onChange={(e) => {
                                                                        handleChangeStringField(e, fields, setFields)
                                                                }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row my-3 d-flex justify-content-end">
                                                        <div className="col-5 d-flex justify-content-end">
                                                            <button type="button" className="text-uppercase btn btn-primary" onClick={() => handleSaveData(clientAnnuaire.id) }>
                                                                {editPhoto != undefined && editPhoto
                                                                        &&
                                                                        (
                                                                            <>
                                                                                Enregistrer
                                                                            </>
                                                                        )
                                                                        ||
                                                                        (
                                                                            <>
                                                                                Enregistrer (Même sans changement)
                                                                            </>
                                                                        )
                                                                    }
                                                            </button>
                                                        </div> 
                                                    </div>
                                                </>
                                            )
                                            ||
                                            (
                                                <div className="alert alert-danger" role="alert">
                                                    Impossible de charger les données, veuillez contacter l'administrateur du site
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-10">
                    <footer className='d-flex justify-content-center my-4 text-muted'>
                    &copy; Tribune Côte d'Azur
                    </footer>
                </div>
        </div>
    </div>
    )
}

export default TrombiGrasse;